import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../pages/AddEdit.css";
import { toast } from "react-toastify";
import { db } from "../Cloud/firebasecon";
import { onValue, ref } from "firebase/database";
import { Link } from "react-router-dom";
import { getDatabase, set, onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import { doc, deleteDoc } from "firebase/firestore";

const initialState = {
  // Preparation Cost: "",
  category: "",
  desc: "",
  price: "",
  title: "",
};
const AddEdit = () => {
  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});

  const { title, price, desc, category } = state;
  const {id} = useParams();

  useEffect(() => {
    const dataRef = ref(db, "tp_menu");
    onValue(dataRef, (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, [id]);

  useEffect(() => {
    if(id){
      setState({ ...data[id] });
    } else {
      setState({ ...initialState });
    }
    return () => {
      setState({ ...initialState });
    };
  },[id,data]);



  const handleInputChange =  (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const handleSubmit = async (e) => {

    debugger;
    const db2 = getDatabase();
    const data = this.getAllInputs();
    
    console.log(title,category,desc,price)
    set(ref(db2, 'tp_menu/'+0),
    { 
      
      // title: title,
      // price: price,
      // category: category,
      // desc: desc,
      // title: data.title,
      // PreparationCost: 0,
      // isAvailable: "yes",
      // img: ""

    })
    .then(() => {alert("data was update successfully : ")})
    .catch((error)=>{alert("there was an error, details: "+error)});




    const dataRef = ref(db, "tp_menu");
    const db = getDatabase();
    const commentsRef = ref(db, 'tp_menu/' + 3);
    e.preventDefault();
    deleteDoc(doc(db, "tp_menu", 3));
    
    if (!title || price || !category) {
      toast.error("Please provide value in each input field");
    } else {
      onChildRemoved(commentsRef, (data) => {
      
      });
    }
  };

  return (
    <div className="mainBackground" style={{ marginTop: "100px" }}>
     <Link to={`/MenuUpdate`}>
          <button className="btn-add"> Go Back TO Menu List</button>
        </Link>
      <form
        style={{
          margin: "auto",
          padding: "15px",
          maxWidth: "400px",
          alignContent: "center",
        }}
        onSubmit={handleSubmit}
      >
        <label htmlFor="title">Enter Menu Title</label>
        <input
          type="text"
          id="title"
          name="title"
          placeHolder="Enter Food Name..."
          value={title || ""}
          onChange={handleInputChange}
        />
        <label htmlFor="category">Enter Menu Categories:</label>
        <input
          type="category"
          id="category"
          name="category"
          value={category || ""}
          onChange={handleInputChange}
        />
        <label htmlFor="price">Enter Menu Price</label>
        <input
          type="price"
          id="price"
          name="price"
          placeHolder="Price ..."
          value={price || 0}
          onChange={handleInputChange}
        />

        <label htmlFor="desc">Enter Menu Description:</label>
        <input
          type="desc"
          id="desc"
          name="desc"
          placeHolder="Enter Menu Description:"
          value={desc || ""}
          onChange={handleInputChange}
        />

        <input type="submit" value={id ? "Update": "Save"} />
      </form>
    </div>
  );
};

export default AddEdit;
