import React, { Component } from "react";
import { db } from "../Cloud/firebasecon";
import startFirebase from "../Cloud/firebase";
import {
  onValue,
  set,
  get,
  update,
  ref,
  remove,
  child,
  getDatabase,
} from "firebase/database";
import "../pages/MenuUpdate.css";
import { Link } from "react-router-dom";


// let firebseDBName = "torre_p_menu";
// let firebseDBCrud = "torre_p_menu/";

let firebseDBName = "tp_menu";
let firebseDBCrud = "tp_menu/";


class MenuUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dbs: "",
      menutitle: "",
      menucatagory: "",
      menudesc: "",
      menuprice: "",
      menuhide: "",
      data: [],
      isLoading: false,
    };
    this.interface = this.interface.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    this.setState({
      dbs: startFirebase(),
    });
  }

  fetchData() {
    // Assuming you have already initialized Firebase with appropriate configuration
    // const database = ref.database();
    const dataRef = ref(db, firebseDBName);

    this.setState({ isLoading: true });

    onValue(dataRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const dataArray = Object.keys(data).map((id) => ({
          id,
          ...data[id],
        }));
        this.setState({ data: dataArray });
      } else {
        this.setState({ data: [] });
      }
      this.setState({ isLoading: false });
    });
  }
  getAllInputs() {
    return {
      title: this.state.menutitle,
      price: Number(this.state.menuprice),
      category: this.state.menucatagory,
      desc: this.state.menudesc,
      isAvailable: this.state.menuhide,
    };
  }

  handleEdit(id) {
    // Handle edit logic here
    console.log("Edit item with id:", id);
  }

  getUpdatedData = (id, peram) => {
    debugger;
    var finalid = id;
    if (id == -1) {
      finalid = 1;
    } else {
      finalid = id;
    }
    console.log(id, peram.title);
    this.setState({
      menutitle: peram.title,
      menucatagory: peram.category,
      menudesc: peram.desc,
      menuprice: peram.price,
      menuhide: peram.isAvailable,
    });
  };

  handleUpdate(id, peram) {
    debugger;
    var insertId = id;
    const data = this.getAllInputs();
    let name = data.title;
    let menuPrice = data.price;
    let menuCatagory = data.category;
    if (!name || !menuPrice || !menuCatagory) {
      alert("Please provide value in each input field");
    } else {
      if (id == 0) {
        insertId = 0;
      } else {
        insertId = id - 1;
      }
      const db2 = getDatabase();
      update(ref(db2, firebseDBCrud + insertId), {
        id: id,
        title: data.title,
        price: data.price,
        category: data.category,
        desc: data.desc,
        PreparationCost: 0,
        isAvailable: data.isAvailable,
        // img: "../images/logoRed.jpg",
      })
        .then(() => {
          alert("Data was update successfully ");
          this.setState({
            menutitle: "",
            menucatagory: "",
            menudesc: "",
            menuprice: "",
            menuhide: "",
          });
        })
        .catch((error) => {
          alert("there was an error, details: " + error);
        });
    }
  }

  callUpdateMenu(id) {}

  handleDelete(id) {
    // alert("data was update successfully : "+id-1);
    const db2 = this.state.dbs;
    remove(ref(db2, firebseDBCrud + id))
      .then(() => {
        alert("Data was Delete successfully");
      })
      .catch((error) => {
        alert("there was an error, details: " + error);
      });
  }
  interface(event) {
    const id = event.target.id;
    if (id == "addBtn") {
      this.insertData();
    }
  }

  insertData() {
    debugger;
    console.log(this.state.data[0].title);
    const db2 = getDatabase();
    const data = this.getAllInputs();
    var arraysize = this.state.data.length;
    // console.log(this.state.data.length);
    // console.log(
    //   data.menutitle,
    //   data.price,
    //   data.category,
    //   data.desc,
    //   data.title,
    //   data.img
    // );
    set(ref(db2, firebseDBCrud + (arraysize)), {
      id: arraysize + 1,
      title: data.menutitle,
      price: data.price,
      category: data.category,
      desc: data.desc,
      title: data.title,
      PreparationCost: 0,
      isAvailable: "yes",
      img: "../images/logoRed.jpg",
    })
      .then(() => {
        alert("data was Inserted successfully : ");
      })
      .catch((error) => {
        alert("there was an error, details: " + error);
      });
  }

  render() {
    const { data, isLoading } = this.state;

    if (isLoading) {
      return <div>Loading...</div>;
    }

    

    return (
      <div className="mainBackground">
        <>
          <div className="styled-textbox">
            <label>Enter Menu Title: </label>
            <input
              type="text"
              id="menutitlebox"
              value={this.state.menutitle}
              onChange={(e) => {
                this.setState({ menutitle: e.target.value });
              }}
            />
            <br />
            <br />
            <label>Enter Menu Categories: </label>
            <input
              type="text"
              id="menucatagorybox"
              value={this.state.menucatagory}
              onChange={(e) => {
                this.setState({ menucatagory: e.target.value });
              }}
            />
            <br />
            <br />
            <label>Enter Menu Description: </label>
            <input
              type="text"
              id="menudescbox"
              value={this.state.menudesc}
              onChange={(e) => {
                this.setState({ menudesc: e.target.value });
              }}
            />
            <br />
            <br />
            <label>Enter Menu Price: </label>
            <input
              type="text"
              id="menupricebox"
              value={this.state.menuprice}
              onChange={(e) => {
                this.setState({ menuprice: e.target.value });
              }}
            />
            <br />
            <br />
            <label> Hide this ITEM</label>
            <input
              type="text"
              id="hideMenubox"
              value={this.state.menuhide}
              onChange={(e) => {
                this.setState({ menuhide: e.target.value });
              }}
            />
            <br />
            <br />
            <label>Tyepe yes for show no for hide </label>
            <br />
            <br />

            <button id="addBtn" onClick={this.interface}>
              {" "}
              ADD Menu
            </button>
            <br />
            <br />
            {/* <button id="EditBtn" onClick={this.interface}> Update Menu</button> */}

            <br />
            <br />
          </div>
        </>

        {/* <div>
       <Link to={`/AddEdit`}>
          <button className="btn-add"> ADD Menu Item</button>
        </Link>
       </div> */}
        <table className="styled-table">
          <thead>
            <tr className="textStyle">
              <th>ID</th>
              <th>TITLE</th>
              <th>CATEGORY</th>
              <th>PRICE</th>
              <th>HIDE/SHOW</th>
              <th>DESC</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.title}</td>
                <td>{item.category}</td>
                <td>{item.price}</td>
                <td>{item.isAvailable}</td>
                <td>{item.desc}</td>
                <td>
                  {/* <Link to={`/AddEdit/${item.id - 1}`}>
                    <button className="btn btn-edit">Edit</button>
                  </Link> */}
                  <button
                    className="btn btn-view"
                    onClick={() => this.getUpdatedData(item.id - 1, item)}
                  >
                    Get Data To Edit
                  </button>
                  <button
                    className="btn btn-edit"
                    onClick={() => this.handleUpdate(item.id, item)}
                  >
                    Update Menu
                  </button>
                  <button
                    className="btn btn-delete"
                    onClick={() => this.handleDelete(item.id - 1)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default MenuUpdate;
