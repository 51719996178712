import React from "react";
import "../UI/header.css";
import logo1 from "../assets/images/logo1.png";
import logo2 from "../assets/images/logo2.png";
import logo3 from "../assets/images/logo3.png";

const Header = () => {
  return (
    <div className="container">
      <div class="frame">
        <img src={logo1} alt="" height="150" />
      </div>
      <div class="frame">
        <img src={logo2} alt="" height="35" />
      </div>
      <div class="frame">
        <img src={logo3} alt="" height="20" />
      </div>
    </div>
  );
};
export default Header;
