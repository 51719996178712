import "../App.css";
import "../index.css";
import React, { useState, useEffect } from "react";
import Categories from "../Categories";
import items from "../data";
import CardMenu from "../Component/CardMenu";
import Header from "../UI/Header";
import { db } from "../Cloud/firebasecon";
import { onValue, ref } from "firebase/database";
import Menu from "../pages/Menu";

// let firebseDBName = "torre_p_menu";
let firebseDBName = "tp_menu";

function MenuList() {
  const [projects, setProjects] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState();
  const [menuItems, setMenuItems] = useState(projects);
  const [activeCategory, setActiveCategory] = useState("");

  var allCategories = [];
  const setCategoriess = async (data) => {
    debugger;
    allCategories = await [
      "all",
      ...new Set(data.map((item) => item.category)),
    ];
    setCategories(allCategories);
    setMenuItems(data);
    setIsLoading(false);
  };

  const filterItems = (category) => {
    setActiveCategory(category);
    if (category === "all") {
      setMenuItems(projects);
      return;
    } else if (category === "") {
      setMenuItems(projects);
      return;
    } else {
      const newItems = projects.filter((item) => item.category === category);
      setMenuItems(newItems);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const query = ref(db, firebseDBName);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        // Object.values(data).map((project) => {
        //   setProjects((projects) => [...projects, project]);
        // });
        setProjects(data);
        setCategoriess(data);
        setActiveCategory("all");
        //filterItems('all');
      }
    });
  }, []);

  

  return (
    <>
      {isloading ? (
        <div>Loading...</div>
      ) : (
        <main>
          <div className="mainBackground">
            <div>
              <Header />
            </div>
            <div className="title">
              <figure></figure>
              <div className="underline"></div>
            </div>
            <div className="row__posters">
              <Categories
                categories={categories}
                activeCategory={activeCategory}
                filterItems={filterItems}
              />
            </div>
            <div>{/* <MenuDisc setMenuItems={menuItems} /> */}</div>
            <div className="section-center">
              <CardMenu setMenuItems={menuItems} />
            </div>
            {/* <Menu items={menuItems} /> */}
            <div className="menuWrapper">
              <Menu />
              </div>
          </div>
        </main>
      )}
      ;
    </>
  );
}

export default MenuList;
