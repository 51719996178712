import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <div className="navMenu">
     
      </div>
      <Outlet />
    </>
  );
};

export default Layout;
