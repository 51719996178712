
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import NoPage from "./pages/NoPage";
import MenuList from "./pages/MenuList";
import MenuUpdate from "./pages/MenuUpdate";
import AddEdit from "./pages/AddEdit";

export default function App() {
  return (
    <BrowserRouter>
    <div className="navMenu"> 
    <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<MenuList />} />
          <Route path="menu" element={<MenuList />} />
          <Route path="MenuUpdate8530" element={<MenuUpdate />} />
          <Route path="/AddEdit" element={<AddEdit />} />
          <Route path="/AddEdit/:id" element={<AddEdit />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </div>
      
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
