// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDlb6nxDc4kexTnZ1ctb38ksUsIue2g3xM",
  authDomain: "torre-picentina.firebaseapp.com",
  projectId: "torre-picentina",
  storageBucket: "torre-picentina.appspot.com",
  messagingSenderId: "7963870442",
  appId: "1:7963870442:web:2d6356a6ef2975ade58393",
  measurementId: "G-0WWX0QJ0EZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);