import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";

function startFirebase(){
    const firebaseConfig = {
        apiKey: "AIzaSyDlb6nxDc4kexTnZ1ctb38ksUsIue2g3xM",
        authDomain: "torre-picentina.firebaseapp.com",
        projectId: "torre-picentina",
        storageBucket: "torre-picentina.appspot.com",
        messagingSenderId: "7963870442",
        appId: "1:7963870442:web:2d6356a6ef2975ade58393",
        measurementId: "G-0WWX0QJ0EZ"
      };

      const fireDb = initializeApp(firebaseConfig);
      return getDatabase(fireDb);
}
  // Initialize Firebase

export default startFirebase;