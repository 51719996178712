import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import React, { useState } from 'react';

export default function CardMenu(props) {
  const { setMenuItems } = props;
  console.log(setMenuItems);
  const divStyleTitle = {
    display: "flex",
  };
  const [zoomed, setZoomed] = useState(false); // State to track zoom effect

  const toggleZoom = () => {
    setZoomed(!zoomed);
  };

  const menuConst = setMenuItems.map((menu) => {
    if (menu.isAvailable == "yes"){
      return (
        <Card className="CardSection">
          <CardActionArea>
            <div className="CardBackground">
              <div style={divStyleTitle}>
                {/* Adding onClick to toggle zoom effect */}
                <CardMedia
                  component="img"
                  image={menu.img}
                  alt="green iguana"
                  loading="lazy"
                  onClick={toggleZoom}
                  className={zoomed ? 'zoomed' : ''} // Apply zoomed class if zoomed state is true
                />
              </div>
              <CardContent>
                <div style={divStyleTitle}>
                  <Typography className="item-info-header">
                    <h4>{menu.title}</h4>
                  </Typography>
                </div>
                <div style={divStyleTitle}>
                  <Typography className="price">
                    <h4>৳{menu.price}</h4>
                  </Typography>
                  <div className="imageDevider"></div>
                  <Typography className="item-info-catagory">
                    <h4>{menu.category}</h4>
                  </Typography>
                </div>
      
                <Typography variant="body" color="text.secondary">
                  {menu.desc}
                  <div className="imageDevider"></div>
                </Typography>
              </CardContent>
            </div>
          </CardActionArea>
        </Card>
      );
    }
  
  });
  return <>{menuConst}</>;
}
