import React from "react";

const Categories = ({ categories, filterItems, activeCategory }) => {
    return (
      <div className="btn-container">
        {categories.map((category, index) => {
          if (category != null){
            return (
            <button
              type="button"
              className={`${
                activeCategory === category ? "filter-btn active" : "filter-btn"
              }`}
              key={index}
              onClick={() => filterItems(category)}
            >
              {category}
            </button>
          );
          }
          
        })}
      </div>
    );
  };
  
  export default Categories;
  