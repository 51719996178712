import React from 'react';
import { Link } from 'react-router-dom';
import './Menu.css'; // Import CSS for styling the menu

const Menu = () => {
  return (
    <div className="menu">
      {/* <Link to="/" className="menuItem">Home</Link> */}
      {/* <Link to="/notifications" className="menuItem">Notifications</Link> */}
      <a href="https://forms.gle/JmDt8KkCdjvasosz6" className="menuItem" target="_blank" rel="noopener noreferrer">Send Feedback</a>
    </div>
  );
};

export default Menu;
